export const COLUMNS = [
  {
    Header: "장치명",
    accessor: "log",
  },

  {
    Header: "아이디",
    accessor: "eq",
  },

  {
    Header: "성명",
    accessor: "eqname",
  },

  {
    Header: "측정시각",
    accessor: "writetime",
  },

  {
    Header: "맥박 (번/분)",
    accessor: "bpm",
  },

  {
    Header: "HRV (ms)",
    accessor: "hrv",
  },

  {
    Header: "온도 (ºC)",
    accessor: "temp",
  },

  {
    Header: "걸음수 (걸음)",
    accessor: "step",
  },

  {
    Header: "걸음거리 (m)",
    accessor: "distanceKM",
  },

  {
    Header: "칼로리 (kCal)",
    accessor: "cal",
  },

  {
    Header: "활동칼로리 (kCal)",
    accessor: "calexe",
  },

  {
    Header: "비정상맥박 (회)",
    accessor: "arrcnt",
  },
  {
    Header: "배터리",
    accessor: "battery",
  },
  {
    Header: "상태",
    accessor: "using",
  },
  {
    Header: "지역",
    accessor: "timezone",
  },
];
